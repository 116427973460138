//
// Boxes
// --------------------------------------------------
.box-counter {
  text-align: center;
  color: $gray-base;
}

.box-counter__icon {
  font-size: 44px;
  line-height: 1;
  color: $primary;
}

.box-counter__title {
  font-size: 18px;
  line-height: 1.3;
}

.box-counter__wrap {
  > * {
    display: inline;
    font-size: 40px;
    font-weight: 200;
    line-height: 1.2;
  }
}

* + .box-counter__title {
  margin-top: 10px;
}

* + .box-counter__wrap {
  margin-top: 20px;
}

@media (min-width: $screen-lg-min) {
  .box-counter__title {
    font-size: $font-size-h5;
  }

  * + .box-counter__wrap {
    margin-top: 30px;
  }
}