/*
*
* Responsive unit
* --------------------------------------------------
*/


@include unit-responsive($medias, 20px, 30px);

// Unit Spacing
//

.unit-spacing-sm {
  @include unit-spacing($medias, 15px, 20px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 15px, 30px);
}

.unit-spacing-lg {
  @include unit-spacing($medias, 25px, 45px);
}

@media (max-width: $screen-xs-max) {
  .unit.unit-horizontal.unit-spacing-md {
    .unit__body {
      padding-left: 18px;
    }
  }
}