//
// Wrappers
// --------------------------------------------------
.object-wrap {
  position: relative;
  overflow: hidden;
}

@media (max-width: $screen-sm-max) {
  .object-wrap__body {
    height: 33vw;
    min-height: 200px;
  }
}

@media (min-width: $screen-md-min) {
  .object-wrap__body {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1px;
    max-width: none;
    height: 100%;
    min-height: 100%;
    max-height: none;
    margin: 0;
    z-index: 0;

    & + * {
      margin-top: 0;
    }
  }

  .object-wrap__body.object-wrap__body-md-right {
    right: 0;
  }

  .object-wrap__body.object-wrap__body-md-left {
    left: 0;
  }
}

@media (min-width: $screen-md-min) {
  // Sizing
  .object-wrap__body-sizing-1 {
    width: 50vw;
  }
}

// Row + gallery wrap
.gallery-wrap {
  @include responsive-offset(10px);
  @media (min-width: $screen-sm-min) {
    @include responsive-offset-media(30px);
  }

  @media (min-width: $screen-xl-min) {
    @include responsive-offset-media(50px);
  }

  &.row {
    @media (max-width: $screen-xs-max) {
      margin-left: -5px;
      margin-right: -5px;

      > [class*='col-'] {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

@media (min-width: $screen-xl-min) {
  .row {
    margin-left: -25px;
    margin-right: -25px;

    > [class*='col-'] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}