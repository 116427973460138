//
// RD-Range
// --------------------------------------------------

.rd-range {
  height: 18px;
  margin-top: 25px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  &__wrap {
    position: relative;
    width: 100%;
    background-color: $gray-base;
    transition: background 100ms cubic-bezier(0.23, 1, 0.32, 1);
    height: 2px;
  }

  &:hover {
    //.rd-range__wrap {
    //  background-color: rgb(158, 158, 158);
    //}

    .rd-range__pointer {
      &:before {
        transform: scale(1);
        -webkit-animation-name: pulse;
        animation-name: pulse;
      }
    }
  }

  // Range Pointer
  &__pointer {
    position: absolute;
    cursor: pointer;
    top: 1px;
    left: 6%;
    z-index: 1;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0% 15%;
    transition: transform .2s cubic-bezier(.35, 0, .25, 1);
    overflow: visible;
    background-color: $primary;
    background-clip: padding-box;

    @media (min-width: $screen-sm-min) {
      width: 12px;
      height: 12px;

      &:before {
        content: '';
        height: 26px;
        width: 26px;
        pointer-events: none;
        background: rgba($primary, 0.16);
        position: absolute;
        top: -7px;
        left: -7px;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 100ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0ms, opacity 60ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0ms;
        pointer-events: none;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
      }

      &.active {
        transform: scale3d(1.5, 1.5, 1.5) translate(-50%, -50%);

        &:before {
          animation: none;
          transform: scale(0);
          opacity: 0;
        }
      }
    }
  }

  // Range line
  &__line {
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    //transition: 0.02s cubic-bezier(.35, 0, .25, 1);
  }

  // Range with tooltip
  &.hasTooltip {
    .rd-range__pointer-tooltip {
      font-size: 12px;
      color: #fff;
      opacity: 0;
      transition: color .3s cubic-bezier(.35, 0, .25, 1);
    }

    .rd-range__pointer {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      top: -38px;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      -webkit-transform: scale(.5) translate3d(0, 59.5px, 0);
      transform: scale(.5) translate3d(0, 59.5px, 0);
      transition: transform .3s cubic-bezier(.35, 0, .25, 1);

      &:before {
        content: none;
      }

      &:after {
        position: absolute;
        content: '';
        left: 0;
        border-radius: 16px;
        top: 19px;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 16px solid $primary;
        opacity: 0;
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0);
        transition: all .2s cubic-bezier(.35, 0, .25, 1);
      }

      &.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0) scale(1) translateX(-14px);
        transform: translate3d(0, 0, 0) scale(1) translateX(-14px);

        &:after {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0) scale(1);
          transform: translate3d(0, 0, 0) scale(1);
        }

        .rd-range__pointer-tooltip {
          opacity: 1;
        }
      }
    }
  }
}

// custom

.rd-range-inner {
  display: flex;
  
 span {
   font-size: 14px;
   padding: 0 2px;
 }
  [class*='rd-range-input-value-'] {
    &:before{
      content: "$";
      display: inline-block;
    }
  }
}