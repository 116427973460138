//
// Utilities custom
// --------------------------------------------------
.height-fill {
  position: relative;
  @include display-flex;
  @include flex-direction(column);
  @include align-items(stretch);

  > * {
    @include flex-grow(1);
    @include flex-shrink(0);
  }
}

.block-top-level {
  position: relative;
  z-index: 10;
}

.block-centered {
  margin-left: auto;
  margin-right: auto;
}

.text-width-1 {
  max-width: 540px;
}

.text-width-2 {
  max-width: 620px;
}

.grid-4 {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  width: 100%;
  max-width: 370px;
  @include spacing(8px, 10px);

  > * {
    width: 25%;
  }
}

.object-displacement-1 {
  @media (min-width: $screen-md-min) {
    margin-top: -45px;
  }
}

// Mods
@media (min-width: $screen-xl-min) {
  .row.range-50 {
    margin-left: -25px;
    margin-right: -25px;

    > [class*='col-'] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}