/*
* @subsection   RD Parallax
*
* @description  Describes style declarations for RD Parallax extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      3.6.4
*/
.rd-parallax {
  &-inner {
    position: relative;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);
    clip: rect(0, auto, auto, 0);
  }

  &-layer[data-type="media"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    pointer-events: none;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &-layer[data-url] {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
  }

  // Styles for Parallax with slider
  &-swiper {
    z-index: 1 !important;
  }

}

.rd-parallax-light {
  #{headings()},
  p {
    color: $white;
  }
}