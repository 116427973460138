/*
*
* Tabs
* --------------------------------------------------
*/

//== Tabs variables
//
//##

// Base
$tabs-color: $gray-base;
$tabs-background: transparent;
$tabs-font: 400 12px/20px $font-family-base;
$tabs-padding: 25px 22px;

// Horizontal tabs
$tabs-horizontal-active-color: $primary;
$tabs-horizontal-active-background: transparent;
$tabs-horizontal-background: transparent;

// Vertical tabs
$tabs-vertical-active-color: $gray-dark;
$tabs-vertical-active-line: $gray-light;

// Base styles
//--------------------------------------

.tabs-custom {
  .nav-custom {
    & > li, & > li > a {
      display: block;
      position: relative;
    }
  }

  .nav-custom-tabs {
    font-size: 0;
    line-height: 0;

    li {
      margin: -1px 0;

      & + li {
        margin-top: 7px;

        @media (min-width: $screen-sm-min) {
          margin-top: 0;
          margin-left: 9px;
        }
      }
    }
  }
}

.tabs-custom {
  .nav-custom-tabs {
    li a {
      position: relative;
      padding: 15px 25px;
      font: $tabs-font;
      color: $white;
      text-align: center;
      vertical-align: middle;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      transition: .3s all ease;
      background-color: $gray-dark;
    }

    li a:hover,
    li.active a {
      background-color: $primary;
      color: $white;
    }
  }
  @media (min-width: $screen-sm-min) {
    .nav-custom-tabs {
      li a {
        background-color: $tabs-background;
        color: $tabs-color;
        padding: $tabs-padding;
      }
      li a:hover,
      li.active a {
        color: $primary;
        background-color: $tabs-background;
      }
    }
  }
}

.tab-content {
  background-color: transparent;
  padding: 25px 15px 25px;
  border:  1px solid $gray-1;;

  @media (min-width: $screen-sm-min) {
    padding: 60px 0 30px;
    border:0;
    border-top: 1px solid $gray-1;

    p + h5 {
      margin-top: 45px;
    }
  }
}

// Horizontal tabs
//--------------------------------------

.tabs-custom {
  &.tabs-horizontal {
    @media (min-width: $screen-sm-min) {
      .nav-custom-tabs {
        position: relative;
        @include justify-content(center);
        @include display-flex;

        li a {
          display: block;
          background: $tabs-horizontal-background;
        }

        li a:hover,
        li.active a {
          color: $tabs-horizontal-active-color;
          background: $tabs-horizontal-active-background;
          border-color: $tabs-horizontal-active-background;

          &:before {
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
      }

      .nav-custom__align-left {
        @include justify-content(flex-start);

        li:first-of-type a {
          padding-left: 0;
        }
      }
    }
  }
}

// Vertical tabs
//--------------------------------------

.tabs-custom {
  &.tabs-vertical {
    @media (min-width: $screen-sm-min) {
      @include display-flex;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(stretch);

      .nav-custom-tabs {
        @include display-flex;
        @include flex-direction(column);
        @include flex-wrap(nowrap);
        @include align-items(stretch);
        @include flex-shrink(0);
        max-width: 50%;
      }

      .tab-content {
        @include flex-grow(1);
        padding-top: 26px;
      }

      .nav-custom-tabs {
        text-align: left;

        li.active,
        li:hover {
          background-color: $tabs-vertical-active-line;

          a {
            color: $tabs-vertical-active-color;
            background-color: $tabs-vertical-active-line;
          }
        }

        li + li {
          margin-top: 12px;
          margin-left: 0;
        }

        li a {
          display: inline-block;
          text-align: left;
          padding-left: 35px;
          @media (min-width: $screen-lg-min) {
            min-width: 160px;
          }
        }
      }

      .tab-content {
        padding: 26px 55px 25px 30px;
      }
    }

    @media (min-width: $screen-lg-min) {
      .nav-custom-tabs {
        min-width: 170px;
      }
    }
  }
}