/*
*
* Circle Progress Bars
* --------------------------------------------------
*/


.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;

  canvas {
    vertical-align: middle;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 100;
    font-size: 40px;
    line-height: 40px;
    transform: translate(-50%, -50%);
    color: $gray-base;

    &:after {
      content: "%";
    }
  }
}

.progress-bar-circle-title {
  font-size: 24px;
  font-weight: 300;
  color: $gray-base;
  line-height: 1.4;

  * + & {
    margin-top: 10px;
  }
  @media (min-width: $screen-sm-min) {
    margin-top: 23px;
  }
}
