//
// Isotope
// --------------------------------------------------
$isotope-default-color: $gray;
$isotope-default-color-active: $white;
$isotope-default-border: $isotope-default-color-active;
$isotope-nav-spacing: 5px;

$isotope-inverse-color: $white;
$isotope-inverse-color-active: $primary;
$isotope-inverse-border: $isotope-inverse-color-active;

[data-isotope-layout] {
  z-index: 0;
  display: block;
  min-height: 160px;
  transition: .4s all ease;

  &:after {
    content: '';
    position: absolute;
    margin-top: 15px;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    background-image: url("../images/isotope-loader.png");
    background-position: -1152px 0;
    animation: 0.7s sprite-animation steps(18) infinite;
    transition: .4s all ease;
    transform: translate(-50%, -50%);
  }

  [class*="col-"] {
    display: block;
    opacity: 0;
    will-change: transform;
    backface-visibility: hidden;
    transition: .1s opacity ease-in;
    margin-top: 30px;
  }
  @media (min-width: $screen-sm-min) {
    [class*="col-"] {
      margin-top: 50px;
    }
  }

  &.isotope--loaded {
    [class*="col-"] {
      opacity: 1;
    }

    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
}

[data-x-mode="design-mode"] {
  [data-isotope-layout] {
    [class*="col-"] {
      opacity: 1;
    }
    
    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.isotope-range-default {
  @include responsive-offset(30px);
}

// Isotope defaults
.isotope-filters {
  $value: 10px;

  > * {
    margin-top: 0;
    vertical-align: middle;
  }

  .inline-list {
    position: relative;
    transform: translateY(-$value);
    margin: 0;
    word-spacing: 0;

    li {
      display: inline-block;
      padding: 0;
      margin-top: $value;
    }

    a {
      position: relative;
      transition: .3s;
      color: $text-color;
    }
  }

  @media (min-width: $screen-md-min) {
    .inline-list {
      > li:not(:last-child) {
        margin-right: $isotope-nav-spacing;
      }

      a {
        border: 2px solid $isotope-default-border;
        padding: 12px 20px 12px;

        &,
        &:active,
        &:focus {
          color: $isotope-default-color;
        }

        &:hover,
        &.active {
          color: $isotope-default-color-active;
        }
      }
    }

    .isotope-filters-trigger {
      display: none;
    }
  }
}

// Isotope filters responsive
.isotope-filters-responsive {
  position: relative;
  z-index: 10;

  @media (max-width: $screen-xs-max) {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $screen-sm-max) {
    vertical-align: middle;

    .isotope-filters-toggle {
      padding: 8px 15px;

      .caret {
        margin-left: 5px;
      }
    }

    > li {
      position: relative;
      vertical-align: middle;
      padding: 0;
    }

    > li:first-child {
      margin-right: 8px;
    }

    > li + li {
      margin-top: 8px;
    }

    .isotope-filters {
      position: absolute;
      top: 49px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      width: 200px;
      padding: 15px;
      background: $white;
      border-radius: 3px;
      text-align: left;
      visibility: hidden;
      opacity: 0;
      transition: .33s all ease;
      border: 1px solid darken($white, 5%);

      &.active {
        visibility: visible;
        opacity: 1;
      }

      .inline-list {
        width: 100%;
      }

      .inline-list a {
        display: inline-block;
        width: 100%;
        padding: 3px 8px;
        font-size: 12px;
        color: $gray-base;
        border: 0;
        background-color: transparent;

        &.active,
        &:hover {
          color: $primary;
          background-color: rgba($primary, .1);
        }
      }

      li {
        display: block;
        width: 100%;
      }

      li + li {
        margin-top: 6px;
      }
    }

    @media (min-width: $screen-xs-min) {
      .isotope-filters {
        left: 0;
        transform: none;
      }

      > li {
        display: inline-block;
        margin: 0;
      }

      > li + li {
        margin-top: 0;
      }
    }

    @media (min-width: $screen-sm-min) {
      .isotope-filters {
        width: 250px;

        .inline-list a {
          padding: 5px 10px;
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .inline-list {
      > li:not(:last-child) {
        margin-right: $isotope-nav-spacing;
      }

      a {
        border: 2px solid $isotope-default-border;
        padding: 12px 20px 12px;

        &,
        &:active,
        &:focus {
          color: $isotope-default-color;
        }

        &:hover,
        &.active {
          color: $isotope-default-color-active;
        }
      }
    }

    .isotope-filters-toggle,
    > li:first-child {
      display: none;
    }
  }
}

* + .isotope,
* + .isotope-filters-responsive {
  margin-top: 0;
}

* + .isotope-wrap {
  margin-top: 50px;
  @media (min-width: $screen-lg-min) {
    margin-top: 73px;
  }
}

.isotope-filters-toggle {
  display: block;
  border: 0;
  outline: 0;
  margin-left: auto;
  margin-right: auto;
}
